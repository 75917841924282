<template>
  <v-banner id="lama-layer-box" color="#FFF" class="layerbox__lama">
    <v-row
      v-for="(layer, idx) in lamaLayer"
      :key="idx"
      class="d-flex layer__button"
      @click="toogleLayerVisibility(layer)"
    >
      <v-col
        cols="2"
        class="d-flex justify-center align-center layerbox__lama_icon"
      >
        <img
          :class="{ border__active: layer.visible }"
          width="100%"
          src="/icons/lama-icon.png"
          alt=""
        />
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <p style="margin-bottom: 0;">
          Camada de Visualização <strong>{{ layer.name }}</strong>
        </p>
      </v-col>

      <v-icon small class="mr-5" :color="layer.visible ? '#8b4b2f' : ''">
        {{ layer.visible ? "mdi-eye" : "mdi-eye-off-outline" }}
      </v-icon>
    </v-row>

    <v-row @click="toggleImagens(ortofotos)" v-if="showImageToggle">
      <v-col cols="12" class="d-flex justify-center align-center">
        <p style="margin-bottom: 0;">
          <strong>Imagem antes do rompimento</strong>
        </p>

        <v-icon small class="ml-5" :color="foto.visible ? 'brown' : ''">
          {{ foto.visible ? "mdi-eye" : "mdi-eye-off-outline" }}
        </v-icon>
      </v-col>
    </v-row>
  </v-banner>
</template>

<script>
export default {
  data() {
    return {
      foto: {
        visible: true,
      },
    };
  },
  props: [
    'showImageToggle'
  ],
  methods: {
    toogleLayerVisibility: function(layer) {
      this.$store.commit("toggleLayer", layer);
    },

    toggleImagens: function(layers) {
      layers.forEach((item) => {
        this.toogleLayerVisibility(item);
      });
      this.foto.visible = !this.foto.visible
    },
  },

  computed: {
    lamaLayer() {
      let lama = this.$store.getters["layers"].filter(
        (item) => item.categoria == "lama"
      );
      return lama;
    },

    ortofotos() {
      let imagens = this.$store.getters["layers"].filter(
        (item) => item.categoria == "ortofoto-antes"
      );
      return imagens;
    },
  },
};
</script>

<style>
.layerbox__lama {
  position: absolute;
  bottom: 2em;
  margin-left: 1em;
  margin-top: 0.5em;
  max-width: 350px;
  padding: 0;
  z-index: 5;
  user-select: none;
  transition: all 400ms 100ms;

  cursor: pointer;
}

.layerbox__lama p {
  font-size: 0.5em;
}

.layerbox__lama_icon img {
  border-radius: 50%;
}

.border__active {
  border: 2px solid brown;
}

.images_buttons {
  display: flex;
  cursor: pointer;
}

.images_buttons p {
  margin: 0;
}

.images_button_boxlama {
  margin: 0;
  padding: 0;
  height: 2em;
  width: 100%;
}

.images_button_boxlama p {
  margin-bottom: 0;
}

</style>
