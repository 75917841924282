<template>
  <div id="sidebar">
    
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Camadas
          </v-expansion-panel-header>
        <v-expansion-panel-content class="content__sidebar_panel pa-0">
            <v-card-actions class="d-flex justify-space-between">
              <v-btn text color="green" @click="showUsoOcupacao = !showUsoOcupacao">
                Usos e Ocupações
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-if="showUsoOcupacao">
                <v-virtual-scroll
                  :items="UsoOcupacaoLayers"
                  :item-height="30"
                  height="160"
                >
                  <template v-slot="{ item }">
                    <div
                      v-if="item.visibleUI == true"
                      @click="toogleLayerVisibility(item)"
                      class="layer_button d-flex justify-start align-center"
                    >
                      <v-icon
                        small
                        class="mr-3 mb-1"
                        :color="item.visible ? 'green lighten-2' : ''"
                      >
                        {{ item.visible ? "mdi-eye" : "mdi-eye-off-outline" }}
                      </v-icon>
                      {{ item.name.toUpperCase() }}
                    </div>
                  </template>
                </v-virtual-scroll>
              </div>
            </v-expand-transition>

            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-space-between">
              <v-btn color="orange lighten-2" text @click="showRefComunitarias = !showRefComunitarias">
                Referências Comunitárias
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-if="showRefComunitarias">
                <v-virtual-scroll
                  :items="ReferenciasComunitarias"
                  :item-height="30"
                  height="210"
                >
                  <template v-slot="{ item }">
                    <div
                      v-if="item.visibleUI == true"
                      @click="toogleLayerVisibility(item)"
                      class="layer_button"
                    >
                      <v-icon
                        small
                        class="mr-3 mb-1"
                        :color="item.visible ? 'orange lighten-2' : ''"
                      >
                        {{ item.visible ? "mdi-eye" : "mdi-eye-off-outline" }}
                      </v-icon>
                      {{ item.name.toUpperCase() }}
                    </div>
                  </template>
                </v-virtual-scroll>
              </div>
            </v-expand-transition>

            <v-divider></v-divider>

            <v-card-actions class="d-flex justify-space-between">
              <v-btn color="cyan lighten-2" text @click="showRecursosHidricos = !showRecursosHidricos">
                Recursos Hídricos
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-if="showRecursosHidricos">
                <v-virtual-scroll
                  :items="RecursosHidricos"
                  :item-height="30"
                  height="120"
                >
                  <template v-slot="{ item }">
                    <div
                      v-if="item.visibleUI == true"
                      @click="toogleLayerVisibility(item)"
                      class="layer_button"
                    >
                      <v-icon
                        small
                        class="mr-3 mb-1"
                        :color="item.visible ? item.colorUI : ''"
                      >
                        {{ item.visible ? "mdi-eye" : "mdi-eye-off-outline" }}
                      </v-icon>
                      {{ item.name.toUpperCase() }}
                    </div>
                  </template>
                </v-virtual-scroll>
              </div>
            </v-expand-transition>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      myLayer: null,
      showUsoOcupacao: false,
      showRefComunitarias: false,
      showRecursosHidricos: false,
      showOrtofotos: false,
      showLama: false,
    };
  },

  methods: {
    toogleLayerVisibility: function(layer) {
      this.$store.commit("toggleLayer", layer);
    },
  },

  watch: {
    showUsoOcupacao (newVal) {
      if (newVal == true) {
        this.showRefComunitarias = false;
        this.showRecursosHidricos = false
      }      
    },
    showRefComunitarias (newVal) {
      if (newVal == true) {
        this.showUsoOcupacao = false;
        this.showRecursosHidricos = false
      }      
    },
    showRecursosHidricos (newVal) {
      if (newVal == true) {
        this.showUsoOcupacao = false;
        this.showRefComunitarias = false
      }      
    }
  },

  computed: {
    UsoOcupacaoLayers() {
      let usoOcupacao = this.$store.getters["layers"].filter(
        (item) => item.categoria == "uso_ocupacao"
      );
      return usoOcupacao;
    },
    ReferenciasComunitarias() {
      let referenciasComunitarias = this.$store.getters["layers"].filter(
        (item) => item.categoria == "referencia_comunitaria"
      );
      return referenciasComunitarias;
    },
    RecursosHidricos() {
      let recursosHidricos = this.$store.getters["layers"].filter(
        (item) => item.categoria == "recurso_hidrico"
      );
      return recursosHidricos;
    },
  },
};
</script>

<style scoped>

.layer__button {
  cursor: pointer;
  width: 100%;
  margin: auto;
}

.layer__button img {
  width: 100%;
  height: 100%;
  background-color: white;
}

.layer__button p {
  margin: 0;
  font-size: 0.8em;
}

.layer--active {
  background-color: #8b4b2f;
}

</style>
