<template>

    
	<div class="">
		<vmLayer
		:source="layer.props.source"
		type="fill"
		:classes="layer.classes"
		:zIndex="layer.zIndex"
		:paint="{ 'fill-color': '#8b4b2f', 'fill-opacity': 0.9 }"
		:paint-click="{ 'fill-color': '#8b4b2f', 'fill-opacity': 0.8 }"
		multipleFeatureSelectionOn="alt"
		@featureselect="featureselect"
		>
		<!-- <template v-slot:popupHover>
			<VmPopup color="black" max-width="400px">
				<h6> Here goes the pop up content while in <b>HOVER</b> a Feature.</h6>
			</VmPopup>
		</template>

		<template v-slot:popupClick="slotProps">
			<VmPopup max-width="400px">
				{{ slotProps.features && slotProps.features[0] && slotProps.features[0].properties }}				
				<h6>Here goes the pop up content while in <b>CLICK</b> a Feature.</h6>
			</VmPopup>
		
		</template>
		-->
	</vmlayer>

	</div>

</template>

<script>
// 
 // 
export default {

	props: ['layer'],

	data() {
		return {
			features: false,
			opacity: 1
		}
	},	
	
	methods: {
		featureselect: function(layer) {
			if(Array.isArray(layer) && layer[0] && layer[0].features.properties) {
				this.features = layer[0].features.properties
			} else {
				this.features = false
			}
		}
	}

}
</script>

<style lang="stylus" scoped>


</style>
