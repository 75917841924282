<template>
  <div class="mapdiv">
    <VueMapbox
      :accessToken="accessKey"
      mapStyle="mapbox://styles/felixxxguilherme/cl2779pi1000614nyk1letobm"
      height="100%"
      width="100%"
      ref="mapbox"
      :maxBounds="[-43.5135, -20.38752, -43.05088, -20.14495]"
      :interactive="true"
      @load="OnLoad"
    >
      <template v-for="layer in layers">
        <component
          v-if="layer.visible"
          :key="layer._id"
          v-bind:is="layer.componentName"
          :layer="layer"
        >
        </component>
      </template>

      <slot name="layers"> </slot>

      <InitialLayers />
    </VueMapbox>
  </div>
</template>

<script>
//
import Vue from "vue";
// import mapboxgl from "mapbox-gl";

import forEach from "lodash/forEach";

function requireAllComponents(requireContext) {
  var keys = requireContext.keys();
  forEach(keys, (k) => {
    var modulo = requireContext(k);
    var nome = k.match(/([^/]*).vue$/);
    Vue.component(nome[1], modulo.default || modulo);
  });
}
requireAllComponents(
  require.context("./layers", true, /[A-Za-z]\w+\.(vue|js)$/)
);

export default {
  name: "",
  data() {
    return {
      accessKey: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
    };
  },

  computed: {
    layers: function() {
      return this.$store.getters["layers"];
    },
  },

  methods: {
    OnLoad(ctx) {
      try {
        // const nav = new mapboxgl.NavigationControl();
        // ctx.map.addControl(nav, "top-right");
        ctx.map.setMaxZoom(18);
        ctx.map.on("zoom", () => {
          let zoom = ctx.map.getZoom();
          this.$emit("zoomed", zoom);
        });
      } catch (err) {
        console.log(err, "Erro");
      }
    },
  },

  mounted() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
    } else {
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
  },
};
</script>

<style scoped>
.mapdiv {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
}
</style>
