<template>
  <div class="">
    <vmLayer
      :source="layer.props.source"
      type="fill"
      :paint="{ 'fill-pattern': layer.pattern }"
      :classes="layer.classes"
      :zIndex="layer.zIndex"
      multipleFeatureSelectionOn="alt"
      @featureselect="featureselect"
      :minzoom="14"
    >
      <template class="teste" v-slot:popupHover>
        <VmPopup max-width="500px" v-if="layer.description">
          <v-banner dense rounded two-line>
            <v-avatar slot="icon">
              <v-img
                max-width="100%"
                :src="`/patterns/${layer.pattern}-pattern.png`"
              ></v-img>
            </v-avatar>

            <span><strong>{{ layer.name }}</strong></span>

            <p>
              {{ layer.description }}
            </p>

            <!-- <template v-slot:actions>
                  <v-btn
                    text
                    color="deep-purple accent-4"
                  >
                    Action
                  </v-btn>
                </template> -->
          </v-banner>
        </VmPopup>
      </template>
    </vmLayer>

    <VmImage
      :name="layer.pattern"
      :url="`/patterns/${layer.pattern}-pattern.png`"
    />
  </div>
</template>

<script>
//
//
export default {
  name: "FillPatternDefault",

  props: ["layer"],

  data() {
    return {
      features: false,
      opacity: 1,
    };
  },

  methods: {
    featureselect: function(layer) {
      if (Array.isArray(layer) && layer[0] && layer[0].features.properties) {
        this.features = layer[0].features.properties;
      } else {
        this.features = false;
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
