<template>
  <section class="">
    <BarraLateral ref="barra_lateral" v-if="show" />
    <LamaLayerBox :showImageToggle="showImageToggle" />
    <MapaBase :style="{ opacity: mapOpacity }" @zoomed="showSideBar" />

    <v-overlay opacity="0.2" :value="showTutorial" class="text-center">
      <v-dialog v-model="dialog" max-width="700" scrollable persistent>
        <v-sheet outlined color="#e06919" rounded>
          <v-card class="pa-5">
            <v-card-text>
              <p class="tutorial-title">Bem vindo ao Museu Virtual !</p>
              <p class="tutorial-text">
                Percorra as comunidades atingidas de Mariana e conheça mais
                sobre a vida e a luta no território.
              </p>
              <p class="tutorial-info">
                Gostaria de acompanhar os primeiros passos com o nosso tutorial?
              </p>
              <p class="tutorial-info2">
                Indicamos acessar pelo wi-fi para uma melhor experiência.
              </p>
            </v-card-text>

            <v-row class="d-flex justify-center align-center">
              <v-col
                cols="12"
                md="6"
                class="d-flex justify-center align-center"
              >
                <v-btn
                  class="white--text"
                  color="rgb(224, 105, 25)"
                  @click="startTour()"
                >
                  Acompanhar com o tutorial!
                </v-btn></v-col
              >
              <v-col cols="12" md="6" class="d-flex justify-center align-center"
                ><v-btn @click="closeModal('dialog')">
                  Não, obrigado.
                </v-btn></v-col
              >
            </v-row>
          </v-card>
        </v-sheet>
      </v-dialog>
      <v-dialog v-model="projeto" max-width="800" scrollable persistent>
        <v-sheet outlined color="#e06919" rounded>
          <v-card class="pa-5">
            <v-row class="d-flex justify-center align-center">
              <img class="img" src="./assets/menu2.png" alt="" />
            </v-row>
            <v-card-text>
              <p class="tutorial-info">
                Na barra de navegação superior, em <strong>O Projeto</strong>,
                você vai entender como construímos este material e quais
                metodologias utilizamos.
              </p>
            </v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="4" class="d-flex justify-center align-center"
                ><v-btn text @click="closeModal('projeto')">
                  Pular
                </v-btn></v-col
              >
              <v-col
                cols="12"
                md="4"
                class="d-flex justify-center align-center"
              >
                <v-btn
                  class="white--text"
                  color="rgb(224, 105, 25)"
                  @click="nextModal('territorio')"
                >
                  Próximo
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-dialog>

      <v-dialog v-model="territorio" max-width="800" scrollable persistent>
        <v-sheet outlined color="#e06919" rounded>
          <v-card class="pa-5">
            <v-row class="d-flex justify-center align-center">
              <img class="img" src="./assets/menu2-1.png" alt="" />
            </v-row>
            <v-card-text>
              <p class="tutorial-info">
                Na barra de navegação superior, em
                <strong> O Território</strong>, você irá conhecer mais sobre as
                comunidades, suas culturas e tradições, além da memória da
                ocupação pré-rompimentos.
              </p>
            </v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="4" class="d-flex justify-center align-center"
                ><v-btn text @click="closeModal('territorio')">
                  Pular
                </v-btn></v-col
              >
              <v-col
                cols="12"
                md="4"
                class="d-flex justify-center align-center"
              >
                <v-btn
                  class="white--text"
                  color="rgb(224, 105, 25)"
                  @click="nextModal('conteudo')"
                >
                  Próximo
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-dialog>
      <v-dialog v-model="conteudo" max-width="800" scrollable persistent>
        <v-sheet outlined color="#e06919" rounded>
          <v-card class="pa-5">
            <v-row class="d-flex justify-center align-center">
              <img class="img" src="./assets/menu2-2.png" alt="" />
            </v-row>
            <v-card-text>
              <p class="tutorial-info">
                Na barra de navegação superior, em
                <strong>Conteúdos Temáticos</strong>, você poderá aprofundar seu
                conhecimento sobre a vida dessas comunidades. Explore diversos
                temas: criança e adolescente, gênero, águas, cultura alimentar,
                moradia, discriminação, religiosidade, entre outros.
              </p>
            </v-card-text>

            <v-row class="d-flex justify-center align-center">
              <v-col cols="12" md="4" class="d-flex justify-center align-center"
                ><v-btn text @click="closeModal('conteudo')">
                  Pular
                </v-btn></v-col
              >
              <v-col
                cols="12"
                md="4"
                class="d-flex justify-center align-center"
              >
                <v-btn
                  class="white--text"
                  color="rgb(224, 105, 25)"
                  @click="nextModal('start')"
                >
                  Próximo
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-dialog>

      <v-dialog v-model="start" max-width="800" scrollable persistent>
        <v-sheet outlined color="#e06919" rounded>
          <v-card class="pa-5">
            <v-card-text>
              <p class="tutorial-info">
                Vamos conhecer o Mariana Território Atingido com respeito e
                cuidado. Tudo aqui pertence aos atingidos e atingidas.
              </p>
            </v-card-text>
            <v-row class="d-flex justify-center align-center">
              <v-col cols="4" md="6" class="d-flex justify-center align-center">
                <v-btn
                  small
                  class="white--text"
                  color="rgb(224, 105, 25)"
                  @click="closeModal('start')"
                >
                  Conhecer Mariana Território Atingido
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-sheet>
      </v-dialog>
    </v-overlay>

    <v-tour
      class="teste"
      name="myTour"
      :options="options"
      :steps="steps"
      :callbacks="callbacks"
    ></v-tour>
  </section>
</template>

<script>
import MapaBase from "../modules/Mapa/MapaBase.vue";
import BarraLateral from "../layout/BarraLateral.vue";
import LamaLayerBox from "../layout/LamaLayerBox.vue";

// import Tutorial from '../layout/Tutorial.vue'

export default {
  name: "Home",
  components: {
    MapaBase,
    BarraLateral,
    LamaLayerBox,
  },

  data() {
    return {
      callbacks: {
        onNextStep: this.showCamadas,
        onFinish: this.finishTour,
      },
      showTutorial: true,
      mapOpacity: 1,
      show: false,
      showImageToggle: false,
      dialog: true,
      conteudo: false,
      territorio: false,
      projeto: false,
      start: false,

      options: {
        labels: {
          buttonSkip: "Pular",
          buttonPrevious: "Anterior",
          buttonNext: "Próximo",
          buttonStop: "Próximo",
        },
      },

      steps: [
        {
          target: "#lama-layer-box", // We're using document.querySelector() under the hood
          content:
            "Acionando a opção “Camada de visualização Lama” é possível observar o percurso do rejeito sobre as comunidades no entorno do Rio Gualaxo do Norte",
        },
        {
          target: "#sidebar",
          content:
            "Ao clicar em uma comunidade, você poderá escolher quais camadas visualizar.",
        },
        {
          target: "#sidebar",
          content:
            "Você poderá visualizar os usos e ocupações, as referências comunitárias e os recursos hídricos do território. <br> Clique para “ativar“ ou “desativar” as camadas.",
        },
      ],
    };
  },

  methods: {
    showCamadas(currentStep) {
      this.show = true;
      if (currentStep === 1) {
        console.log("2 pra 3");
      }
    },

    finishTour() {
      this.show = false;
      this.projeto = true;
      this.mapOpacity = 1;
      this.showTutorial = false;
    },
    nextModal(name) {
      if (name == "territorio") {
        this.projeto = false;
        this.territorio = true;
      }
      if (name == "conteudo") {
        this.territorio = false;
        this.conteudo = true;
      }
      if (name == "start") {
        this.conteudo = false;
        this.start = true;
      }
    },

    previousModal(name) {
      if (name == "conteudo") {
        this.conteudo = true;
        this.start = false;
      }
      if (name == "territorio") {
        this.conteudo = false;
        this.territorio = true;
      }
      if (name == "projeto") {
        this.territorio = false;
        this.projeto = true;
      }
    },

    showSideBar(e) {
      if (e >= 14) {
        this.show = true;
        this.showImageToggle = true;
        return;
      }
      this.show = false;
      this.showImageToggle = false;
      return;
    },
    closeModal(name) {
      if (name == "dialog") {
        this.dialog = false;
        this.showTutorial = false;
      }
      if (name == "conteudo") {
        this.conteudo = false;
        this.showTutorial = false;
      }
      if (name == "territorio") {
        this.territorio = false;
        this.showTutorial = false;
      }
      if (name == "projeto") {
        this.projeto = false;
        this.showTutorial = false;
      }
      if (name == "start") {
        this.start = false;
        this.showTutorial = false;
      }
    },

    startTour() {
      this.dialog = false;
      this.mapOpacity = 0.7;
      this.$tours["myTour"].start();
    },
  },

  mounted: function() {},

  created() {},
};
</script>

<style>
.v-step__header {
  background: #e06919 !important;
}
.v-step {
  font-size: 16px !important;
  line-height: 18px !important;

  /* Verde escuro - texto */
  color: #1b211b !important;
  background: #fff !important;
  border-radius: 8px !important;
  border: 2px solid #e06919 !important;
}

.v-tour__target--highlighted {
  background-color: #e06919 !important;
}

.v-step__arrow {
  background-color: #e06919 !important;
}

.v-step__button {
  color: #fff !important;
  background: #e06919 !important;
}

.tutorial-text {
  font-size: 24px;

  /* or 36px */
  text-align: center;

  /* Laranja - secundário */
  color: #cd6b3e;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tutorial-title {
  text-align: center;
  font-size: 40px;

  /* Laranja - Primário */
  color: #e06919;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tutorial-info {
  /* identical to box height */
  text-align: center;

  /* Verde escuro - texto */
  color: #1b211b;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tutorial-info2 {
  /* identical to box height */
  text-align: center;

  /* Verde escuro - texto */
  color: #1b211b;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

@media (max-width: 600px) {
  .tutorial-title {
    font-size: 24px;
  }

  .tutorial-info {
    font-size: 14px;
  }

  .tutorial-text {
    font-size: 16px;
  }
}

.opacity-class {
  opacity: 0.6;
}
</style>
