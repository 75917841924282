<template>
  <div class="">
    <vmLayer
      :source="layer.props.source"
      type="circle"
      :layout="layer.layout"
      :paint="{
        'circle-color': '#E06919',
        'circle-radius': 6,
      }"
      multipleFeatureSelectionOn="alt"
      @featureselect="featureselect"
      @click="flyAnimation"
    >
    </vmLayer>
  </div>
</template>

<script>
//
//
export default {
  name: "PointCircleDefault",

  props: ["layer"],

  data() {
    return {
      features: false,
      opacity: 1,
    };
  },

  methods: {
    featureselect: function(layer) {
      if (Array.isArray(layer) && layer[0] && layer[0].features.properties) {
        this.features = layer[0].features.properties;
      } else {
        this.features = false;
      }
    },
    flyAnimation(param1, param2) {
      param2.flyTo({
        center: [param1.lngLat.lng, param1.lngLat.lat],
        zoom: 16,
        bearing: 3,
        speed: 0.8,
        curve: 2,
        easing: (t) => t,
        essential: true,
      });
    },
  },
};
</script>

<style lang="stylus" scoped></style>
