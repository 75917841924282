<template>
  <div class="">
    <vmLayer
        :source="layer.props.source"
        type="raster"
        :minzoom="14"
    >
    </vmLayer>
  </div>
</template>

<script>

export default {

  name: 'OrtofotoLayer',

  props: ["layer"],

  data() {
    return {
      opacity: 1,
      paint: {},
    };
  },

  methods: {},
};
</script>

<style lang="stylus" scoped></style>
